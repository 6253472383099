/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveMeta } from "@dataResolvers";
import { Home as PageTemplate } from "@pageTemplates";
import moment from "moment-timezone";
import resolveEvent from "../dataResolvers/resolveEvent";

export const query = graphql`
  query {
    craft {
      entries(section: ["teaching", "event"]) {
        ... on Craft_teaching_teaching_Entry {
          type: typeHandle
          title
          teachingExcerpt
          mediaUrl
          uid
          url
          mediaEmbed
          postDate
          showDate
          teachingType
          teachingImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
        ... on Craft_event_event_Entry {
          type: typeHandle
          title
          url
          metaDescription
          eventImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          eventStartTime
          showDate
          repeats
        }
      }
      entry(section: "home") {
        ... on Craft_home_home_Entry {
          # meta
          url
          slug
          title
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
          metaTitle
          # home
          uid
          heroHeading
          mediaUrl
          heroButtonLink {
            url
            text
          }
          heroBackgroundImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          scrimOpacity
          # featuredCards
          featuredCards {
            ... on Craft_featuredCards_featuredCard_BlockType {
              heading
              text
              backgroundImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              scrimOpacity
              featuredCardLink {
                url
                text
              }
            }
          }
          # fill wdth
          fullWidthText
          # split content
          splitContentImageText {
            ... on Craft_splitContentImageText_splitContent_BlockType {
              splitText
              splitImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              splitContentLink {
                text
                url
              }
            }
          }
          reservationsBlock {
            ... on Craft_reservationsBlock_reservationsBlock_BlockType {
              reservationsBlockTitle
              reservationsBlockText
              reservationsBlockLink {
                text
                url
              }
            }
          }
          upcomingEventsBlock {
            ... on Craft_upcomingEventsBlock_upcomingEventsContent_BlockType {
              heading
              subheading
              upcomingEventsLink {
                text
                url
              }
            }
          }
          featuredEvents {
            ... on Craft_event_event_Entry {
              type: typeHandle
              title
              uid
              url
              metaDescription
              eventImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              eventStartTime
              showDate
              repeats
            }
          }
          # teachings
          subheading
          teachingExcerpt
        }
      }
    }
  }
`;

const previewQuery = `
query {
  craft {
    entries(section: ["teaching", "event"]) {
      ... on Craft_teaching_teaching_Entry {
        type: typeHandle
        title
        teachingExcerpt
        mediaUrl
        url
        mediaEmbed
        postDate
showDate
        teachingType
        teachingImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
      }
      ... on Craft_event_event_Entry {
        type: typeHandle
        title
        url
        metaDescription
        eventImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        eventStartTime
showDate
        repeats
      }
    }
    entry(section: "home") {
      ... on Craft_home_home_Entry {
        # home
        uid
        heroHeading
        mediaUrl
        heroButtonLink {
          url
          text
        }
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        scrimOpacity
        # featuredCards
        featuredCards {
          ... on Craft_featuredCards_featuredCard_BlockType {
            heading
            text
            backgroundImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            scrimOpacity
            featuredCardLink {
              url
              text
            }
          }
        }
        # fill wdth
        fullWidthText
        # split content
        splitContentImageText {
          ... on Craft_splitContentImageText_splitContent_BlockType {
            splitText
            splitImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            splitContentLink {
              text
              url
            }
          }
        }
        reservationsBlock {
          ... on Craft_reservationsBlock_reservationsBlock_BlockType {
            reservationsBlockTitle
            reservationsBlockText
            reservationsBlockLink {
              text
              url
            }
          }
        }
        upcomingEventsBlock {
          ... on Craft_upcomingEventsBlock_upcomingEventsContent_BlockType {
            heading
            subheading
            upcomingEventsLink {
              text
              url
            }
          }
        }
        featuredEvents {
          ... on Craft_event_event_Entry {
            type: typeHandle
            title
            url
            metaDescription
            eventImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            eventStartTime
showDate
            repeats
          }
        }
        # teachings
        subheading
        teachingExcerpt
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    // meta
    title,
    metaTitle,
    metaImage,
    metaDescription,
    slug,
    url,
    // other
    heroHeading,
    heroButtonLink,
    heroBackgroundImage,
    featuredCards,
    fullWidthText,
    splitContentImageText,
    reservationsBlock,
    subheading,
    teachingExcerpt,
    upcomingEventsBlock,
    mediaUrl,
    scrimOpacity,
    featuredEvents,
  } = craft.entry;

  const processEvents = events => {
    return events
      .map(e => resolveEvent(e))
      .filter(e => e.orderTime >= moment().startOf("day").utc().valueOf())
      .sort((a, b) => a.orderTime - b.orderTime)
      .map(e => {
        return {
          title: e.title,
          time: e.time,
          content: e.description,
          image: resolveImage(e.image),
          url: e.url,
        };
      });
  };

  const upcomingEvents = (featured, _upcoming) => {
    const upcoming = _upcoming.filter(
      u => !featured.map(f => f.uid).includes(u.uid)
    );
    return [...processEvents(featured), ...processEvents(upcoming)].slice(0, 3);
  };

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      heading: heroHeading,
      button: heroButtonLink,
      mediaUrl,
      image: resolveImage(heroBackgroundImage),
      scrimOpacity,
    },
    // cards,
    cards: featuredCards.map(card => {
      return {
        title: card.heading,
        description: card.text,
        url: card.featuredCardLink?.url,
        image: resolveImage(card.backgroundImage),
        scrimOpacity: card.scrimOpacity,
      };
    }),
    // fullWidth,
    fullWidth: { heading: fullWidthText },
    // leftRight,
    leftRight: splitContentImageText.map(split => {
      return {
        content: split.splitText,
        link: split.splitContentLink,
        image: resolveImage(split.splitImage),
      };
    }),
    // reservations,reservationsBlock
    reservations: {
      heading: reservationsBlock[0].reservationsBlockTitle,
      content: reservationsBlock[0].reservationsBlockText,
      link: reservationsBlock[0].reservationsBlockLink,
    },
    // homeEvents,
    homeEvents: {
      title: upcomingEventsBlock[0].heading,
      content: upcomingEventsBlock[0].subheading,
      events: upcomingEvents(
        featuredEvents,
        craft.entries.filter(e => e.type === "event")
      ),
      button: upcomingEventsBlock[0].upcomingEventsLink,
    },
    // newsletter,
    // community,
    // support,
    teaching: {
      heading: subheading,
      content: teachingExcerpt,
      mediaLabel: "Up Next:",
      button: {
        text: "View All",
        url: "/teachings",
      },
      preview: craft.entries
        .filter(e => e.type === "teaching")
        .map(t => {
          let label = "Read";
          switch (t.teachingType) {
            case "videos":
              label = "Watch";
              break;
            case "classes":
              label = "View";
              break;
            case "audioTeachings":
              label = "Hear";
              break;
            case "podcasts":
              label = "Listen";
              break;
            default:
              break;
          }
          return {
            name: t.title,
            description: t.teachingExcerpt,
            showDate: t.showDate,
            type: {
              text: t.teachingType,
              icon: t.teachingType,
            },
            time: moment(t.postDate)
              .tz("America/Los_Angeles")
              .format("MMM Do, YYYY"),
            mediaUrl: t.mediaUrl,
            mediaEmbed: t.mediaEmbed,
            image: resolveImage(t.teachingImage),
            button: { url: t.url, text: label },
          };
        })[0],
      media: craft.entries
        .filter(e => e.type === "teaching")
        .slice(1, 5)
        .map(t => {
          let label = "Read";
          switch (t.teachingType) {
            case "videos":
              label = "Watch";
              break;
            case "classes":
              label = "View";
              break;
            case "audioTeachings":
              label = "Hear";
              break;
            case "podcasts":
              label = "Listen";
              break;
            default:
              break;
          }
          return {
            name: t.title,
            showDate: t.showDate,
            description: t.teachingExcerpt,
            type: {
              text: t.teachingType,
              icon: t.teachingType,
            },
            time: moment(t.postDate)
              .tz("America/Los_Angeles")
              .format("MMM Do, YYYY"),
            mediaUrl: t.mediaUrl,
            mediaEmbed: t.mediaEmbed,
            image: resolveImage(t.teachingImage),
            button: { url: t.url, text: label },
          };
        }),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
